import { render, staticRenderFns } from "./registerEntry_tl2.vue?vue&type=template&id=a0d02f1c&scoped=true"
import script from "./registerEntry_tl2.vue?vue&type=script&lang=js"
export * from "./registerEntry_tl2.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/registerEntry_tl2.scss?vue&type=style&index=0&id=a0d02f1c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0d02f1c",
  null
  
)

export default component.exports